app.factory('featuresService',
    ['$http', '$q', function ($http, $q) {

        var service = {};

        var features = [];

        service.hasFeature = function (feature) {
            if (features.indexOf(feature) > -1) {
                return true;
            } else {
                return false;
            }
        };

        service.getAllFeatureFlags = function () {
            if (features.length === 0) {

                return $http.get($.path + "/api/featureFlags")
                    .then(function (response) {
                        features = response.data;
                    }).catch(angular.noop);
            }
            return $q.resolve();
        };

        service.areFeaturesEmpty = function () {
            return features.length === 0;
        };

        return service;
    }]);