app.factory('dateHelperService', 
    function () {
        var parseCSharpDate = function(dateTimeString) {
            var dateStyleString = dateTimeString.includes("Date");

            if (dateStyleString) {
                dateTimeString = parseInt(dateTimeString.substr(6));
            }

            return new Date(dateTimeString).setHours(0, 0, 0, 0, 0);
        }

        return {
            parseCSharpDate : parseCSharpDate
        };
    }
);