app.factory("localizationService",
    [
        function() {

            var service = {};
            var localizationData = {};
            var isLocalizationSet = false;

            var kendoLanguages = [
                "bg", "cs", "da", "de", "el", "en", "es", "fa", "fi", "fr", "he", "hu", "hy", "it", "ja", "mk", "nb", "no", "nl", "pl", "pt", "ro", "ru", "sr", "sk", "sv",
                "tr", "uk", "zh"
            ];

            service.setLocalization = function(languageCode,culture) {

                if (!isLocalizationSet) {

                    localizationData.lang = languageCode;

                    localizationData.kendoLang = "en";

                    if ($.inArray(localizationData.lang, kendoLanguages) > -1) {
                        localizationData.kendoLang = localizationData.lang;
                    }

	                if (culture)
						localizationData.culture = culture;
					else
						localizationData.culture = $.culture;

                    service.getScripts(culture);

                    isLocalizationSet = true;
                }
            };

            service.getScripts = function(culture) {

                var toReturn = [];

                toReturn.push($.getScript($.sharedScriptsDir +
                    "/messages/kendo.messages." +
                    localizationData.kendoLang +
                    ".min.js"));

				if (culture) {
					toReturn.push($
						.getScript($.sharedScriptsDir +
							"/cultures/kendo.culture." +
							culture +
							".min.js").then(function () {
							kendo.culture(culture);
						}));
	            }
	            else if (localizationData.culture.indexOf(localizationData.lang) > -1) {
                    toReturn.push($
                        .getScript($.sharedScriptsDir +
                            "/cultures/kendo.culture." +
                            localizationData.culture +
                            ".min.js").then(function() {
                            kendo.culture($.culture);
                        }));

                } else if (localizationData.lang) {
                    toReturn.push($
                        .getScript($.sharedScriptsDir + "/cultures/kendo.culture." + localizationData.lang + ".min.js")
                        .then(function() {
                            kendo.culture(localizationData.lang);
                        }));

                } else {
                    toReturn.push($.getScript($.sharedScriptsDir + "/cultures/kendo.culture.en-US.min.js").then(
                        function() {
                            kendo.culture("en-US");
                        }));

                }

                return toReturn;
            };

            service.setDefaultLanguage = function() {
                return $.getScript($.sharedScriptsDir + "/messages/kendo.messages.en.min.js");
            };

            service.setConfiguredLanguage = function() {
                return $.getScript(
                    $.sharedScriptsDir + "/messages/kendo.messages." + localizationData.kendoLang + ".min.js");
            };


            service.reset = function() {
                isLocalizationSet = false;
            };

            return service;
        }
    ]);