app.factory('calendarService', [
    '$http', function ($http) {

        var service = {};

        service.calculateCalendar = function (data) {
            return $http.post($.path + "/api/calculateCalendar", data);
        };

        service.updateSelectedDate = function (data) {
            return $http.post($.path + "/api/updateSelectedDate", data);
        };

        return service;
    }
]);