app.factory('homeStateService', [
    '$cookies', function ($cookies) {

        var service = {};
        var activeMenuId = null;
        var lastInboxId = null;
        var minutes = 20;

        service.saveActiveTab = function (menuId) {

            var date = new Date();         
            date.setTime(date.getTime() + (minutes * 60 * 1000));
            activeMenuId = menuId;

            $cookies.put('activeMenu', menuId,{
                expires: date
            });
        };
        service.saveLastInboxId = function (inboxIdentifier) {        

            lastInboxId = inboxIdentifier;
            $cookies.put('activeInbox', inboxIdentifier);
        };        
        service.getActiveTab = function () {
            if (!activeMenuId && $cookies.get('activeMenu')) {
                activeMenuId = $cookies.get("activeMenu");           
            }
            return activeMenuId;
        };
        service.getLastInboxId = function () {
            if (!lastInboxId && $cookies.get('activeInbox')) {
                lastInboxId = $cookies.get('activeInbox');
            }
            return lastInboxId;
        };

        service.resetLastInboxId = function () {
            lastInboxId = null;
            $cookies.remove('activeInbox');
        };


        return service;
    }
]);