app.factory('sharedSessionService', [
    '$cookies', 'localizationService', function ($cookies, localizationService) {

        var service = {};
        service.days = 10;

        service.set = function (sharedSession) {

            if ($.forceLogin === 'true') {
                $cookies.putObject('sharedSession',
                    sharedSession);
            } else {

                var date = new Date();
                date.setTime(date.getTime() + (service.days * 24 * 3600 * 1000));

                $cookies.putObject('sharedSession',
                    sharedSession,
                    {
                        expires: date
                    });
            }
        };      

        service.update = function (sharedSession) {

            if ($.forceLogin === 'true') {
                $cookies.putObject('sharedSession', sharedSession);
            } else {
                var date = new Date();
                date.setTime(date.getTime() + (service.days * 24 * 60 * 60 * 1000));

                $cookies.putObject('sharedSession', sharedSession, {
                    expires: date
                });
            }
        };

        service.exists = function () {
            if ($cookies.getObject("sharedSession") == null) {
                return false;
            } else {
                return true;
            }
        };

        service.reset = function () {
            $cookies.remove("sharedSession");
            localizationService.reset();
        };

        service.getSittingKey = function () {
            if ($cookies.getObject("sharedSession") == null) {
                return "";
            }

            return $cookies.getObject("sharedSession").SittingKey;
        };

        service.getSessionKey = function () {
            if ($cookies.getObject("sharedSession") == null) {
                return "";
            }

            return $cookies.getObject("sharedSession").SessionKey;
        };

        service.getUserName = function () {
            if ($cookies.getObject("sharedSession") == null) {
                return "";
            }

            return $cookies.getObject("sharedSession").UserName;
        };

        service.getUser = function () {
            if ($cookies.getObject("sharedSession") == null) {
                return "";
            }

            return $cookies.getObject("sharedSession").User.toLowerCase();
        };

        service.getLanguageCode = function () {
            if ($cookies.getObject("sharedSession") == null) {
                return "";
            }

            return $cookies.getObject("sharedSession").LanguageCode;
		}
		service.getCulture = function () {
            if ($cookies.getObject("sharedSession") == null || $cookies.getObject("sharedSession").Culture == null) {
                return $.culture;
            }
			return $cookies.getObject("sharedSession").Culture;
		}

        service.getAccessLevel = function () {
            if ($cookies.getObject("sharedSession") == null) {
                return "";
            }

            return $cookies.getObject("sharedSession").AccessLevel;
        };

        service.isPublicSession = function () {
            if ($cookies.getObject("sharedSession") == null) {
                return false;
            }

            return $cookies.getObject("sharedSession").IsPublic;
        };

        service.isOpenIdSession = function () {
            if ($cookies.getObject("sharedSession") == null) {
                return false;
            }

            return $cookies.getObject("sharedSession").IsOpenId === true;
        };

        return service;
    }
]);