app.factory('gridService', [
	'$http', "sharedSessionService", function ($http, sharedSessionService) {
		var service = {};

		service.update = function (data) {
			return $http.post($.path + "/api/updateRow", data);
		};

		service.add = function (data) {
			return $http.post($.path + "/api/addRow", data);
		};

		service.addCalendarRow = function (data) {
			return $http.post($.path + "/api/addCalendarRow", data);
		};

		service.delete = function (data) {
			return $http.post($.path + "/api/deleteRow", data);
		};

		service.getTextWidth = function (text, font) {
			// re-use canvas object for better performance
			var escapedText = encodeURIComponent(text);

			var canvas = service.getTextWidth.canvas || (service.getTextWidth.canvas = document.createElement("canvas"));
			var context = canvas.getContext("2d");
			context.font = font;
			var metrics = context.measureText(escapedText);

			return metrics.width;
		}

		service.getMaxTextLength = function (title, rows, colOrder, font) {
			//min value of column width
			var max = 50;

			//check title
			if (title) {
				var titleLength = service.getTextWidth(title, font);
				if (titleLength > max)
					max = titleLength;
			}
			for (var j = 0; j < rows.length; j++) {
				var row = rows[j];
				var text = row.Cells[colOrder].Value;

				var value = service.getTextWidth(text, font);

				if (value > max)
					max = value;
				//max value of column width
				if (max > 1000) {
					max = 1000;
					break;
				}
			};
			return max + 20;
		};

		service.transformValue = function (column, value) {
			// Make sure that input format and display format is the same. Independent of server format.
			if (column && column.type === "numeric" && value !== undefined && value !== null) {

				var culture = sharedSessionService.getCulture();

				if (!culture)
					culture = $.culture;

				var number = (0.1).toLocaleString(culture);
				if (number.indexOf('.') > -1)
					value = value.toString().replace(',', '.');
				else if (number.indexOf(',') > -1)
					value = value.toString().replace('.', ',');

			}
			//escape {{ in text (ng-bind issue) 
			else if (column && column.type === "text" && value !== undefined && value !== null) {
				var escaped = value.replace(/{{/g, "{ {");
				return escaped;
			}
			return value;
		};

		service.IsComputedColumnNumeric = function (column, columnIndex, rows) {
			// we need to check only first value as the flow script is the same for whole column

			var firstRow = rows[0];
			if (firstRow) {

				var text = firstRow.Cells[columnIndex].Value;

				var intText = parseInt(text);
				if (!isNaN(intText))
					return true;
			}

			return false;
		};

		return service;
	}
]);