app.factory('fileService', [
    '$http', function ($http) {

        var service = {};
        var files = [];

        service.download = function (data) {
            return $http.post($.path + "/api/file", data, { responseType: 'arraybuffer' });
        };

        service.get = function (name) {
            var toReturn = null;
            angular.forEach(files, function (file) {
                if (file.name === name) {
                    toReturn = file;
                }
            });
            return toReturn;
        }

        service.getAll = function () {
            return files;
        };

        service.exists = function (name) {
            var q = false;
            angular.forEach(files, function (file) {
                if (file.name === name) {
                    q = true;
                }
            });
            return q;
        }

        service.add = function (file) {          
            files.push(file);
        };

        service.remove = function (name) {
            for (var i = files.length - 1; i >= 0; i--) {
                if (files[i].name === name) files.splice(i, 1);
            }
        };

        service.clear = function () {
            files = [];
        };

        return service;
    }
]);