app.service("sharedThemeService", ["$http", "$q", function ($http, $q) {

    var service = {};

    var theme = null;
    service.getSharedTheme = function () {

        if (theme)
            return theme;

        theme = $http.get($.path + "/api/sharedTheme");
        return theme;
    };

    return service;
}])