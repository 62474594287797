app.factory('translationFlowClientService',
    ['$http', '$q', 'sharedSessionService', function ($http, $q, sharedSessionService) {
        var service = {};
        var translationsDictionary = null;
        var dictionaryLanguageCode = '';

        service.getValueFromDictionary = function (code, defaultValue) {

            if (translationsDictionary) {
                var text = translationsDictionary[code];
                if (text) {
                    return text;
                } else if (defaultValue) {
                    return defaultValue;
                } else {
                    return "";
                }
            } else if (defaultValue) {
                return defaultValue;
            } else {
                return "";
            }

        };
        service.get = function (code) {
            if (translationsDictionary === null) {
                this.getAll(sharedSessionService.getLanguageCode()).then(function () {
                    return service.getValueFromDictionary(code);
                }).catch(angular.noop);
            }
            else {
                return service.getValueFromDictionary(code);
            }
        };

        service.areTranslationsEmpty = function () {
            return translationsDictionary === null; 
        };

        service.cleanAll = function() {
            translationsDictionary = null;
            dictionaryLanguageCode = '';
        };

        service.getAll = function (languageCode) {
            if (translationsDictionary === null || dictionaryLanguageCode !== languageCode) {

                var toSend = {};
                toSend.LanguageCode = languageCode;

                return $http.post($.path + "/api/translations", toSend)
                    .then(function (response) {
                        translationsDictionary = response.data;
                        dictionaryLanguageCode = languageCode;
                    }).catch(angular.noop);
            }

            return $q.resolve();
        };
        return service;
    }]);