app.factory('workflowService', [
    '$http', function ($http) {

        var service = {};
        
        service.getOngoingExecutions = function (withArchive) {
            return $http.get($.path + "/api/inbox/" + withArchive);
        };

        service.getMenuConfiguration = function () {
            return $http.get($.path + "/api/plugins/menus");
        };
        return service;
    }
]);