app.factory('stateService', [
   function () {

       var service = {};

       errorDisplayed = false;

       service.go = function (scope, stateName, stateParams) {
           scope.history.push({ state: stateName, params: stateParams });
           scope.$emit('changeState', { state: stateName, params: stateParams });
       };
       service.goAndReplace = function (scope, stateName, stateParams) {
		   scope.history[scope.history.length - 1] = { state: stateName, params: stateParams };
           scope.$emit('changeState', { state: stateName, params: stateParams });
       };
       service.goBack = function (scope) {
           var st = scope.history.pop();
           scope.$emit('changeState', { state: scope.history[scope.history.length - 1].state, params: scope.history[scope.history.length - 1].params });
       };
       service.goBackToIndex = function (scope, idx) {
           var history = service.getHistory(scope);
           var element = history[idx];
           scope.history.splice(idx + 1, scope.history.length - idx + 1);
           scope.$emit('changeState', { state: element.state, params: element.params });
       };
       service.clear = function (scope) {
           scope.history.splice(0, scope.history.length);
           scope.history.push({ state: 'home', params: {} });
           scope.$emit('changeState', { state: 'home', params: {} });
       };
       service.getStateParams = function (scope) {
           return scope.stateParams;
       };
       service.getHistory = function (scope) {
           return scope.history;
       };
       service.update = function (scope, params) {
           scope.$emit('updateState', params);
       };

       service.reload = function (scope) {
           scope.$emit('reload');
       };
       service.canGoBack = function (scope) {
           if (scope.history.length === 1) {
               return false;
           } else {
               return true;
           }
       };
       service.canExit = function (scope){
           return scope.model.canCancel;
       };
       service.showHideError = function() {
           errorDisplayed = !errorDisplayed;
       };
       service.showError = function() {
           errorDisplayed = true;
       };
       service.hideError = function() {
           errorDisplayed = false;
       };
       service.errorIsDisplayed = function() {
           return errorDisplayed;
       };

       service.errorTypes = [];
       service.containsError = function(statusText) {
           return service.errorTypes.indexOf(statusText) > -1 ? true : false;
       };

       service.checkError = function (statusText) {
           if (!service.containsError(statusText)) {
               service.errorTypes.push(statusText);
           }
       };
       
       service.dismissError = function(statusText) {
           var index = service.errorTypes.indexOf(statusText);
           if (index !== -1) {
               service.errorTypes.splice(index, 1);
           }
       }
       service.dismissAllErrors = function() {
           service.errorTypes = [];
       }

       service.inItemCreationSubworkflow = function (scope) {
           return scope.history.length > 2 && scope.history[scope.history.length - 1].params.isItemCreation;
       };

       return service;
   }
]);