app.service("logoService", ["$http", "$q", function ($http, $q) {

    var logoLocalCache = "logoLocalCache";

    var getLogo = function () {
        var deferred = $q.defer();
        var logo = getLogoFromLocalStorage();

        if (logo && logo !== null) {
            deferred.resolve(logo);
            return deferred.promise; 
        }
        else return getLogoFromServer();
    }

    var getLogoFromLocalStorage = function () {
        return window.localStorage.getItem(logoLocalCache);
    }

    var getLogoFromServer = function () {
        return $http.get($.path + "/api/logo", { cache: false }).then(function (logo) {
            var result = "";
            if (logo.data.Data) {
                result = "data:" + logo.data.ContentType + ";base64," + logo.data.Data;
                window.localStorage.setItem(logoLocalCache, result);
            }
            else if (logo.data.Failure) {
                result = { reason: logo.data.Failure, failure: true };
                //Don't cache because failure
            }
            
            return result;
        }).catch(angular.noop);
    }

    var clearCache = function () {
        window.localStorage.removeItem(logoLocalCache); 
    }

    return {
        GetLogo: getLogo,
        ClearCache: clearCache
    }
}])