app.factory('userStepDataService', [
   function () {

       var service = {};

       service.userStepHasFocus = function (rows) {
           var stepHasFocus = false;
           angular.forEach(rows, function (row) {
               angular.forEach(row.Controls, function (control) {
                   if (control.FocusOnMe && !stepHasFocus) {
                       stepHasFocus= true;                       
                   }
               });
           });

           return stepHasFocus;
       };

       service.pickFocusedControl = function (rows) {

           var focused = false;
        
           angular.forEach(rows, function (row)
           {
               angular.forEach(row.Controls, function (control) {
                   control.FocusOnMe = false;
                   var c = {};
                   c.__type = control.__type;                   

                   if (!focused) {           
                       switch (control.Type) {                          
                   
                           case "binaryOption":
                               control.FocusOnMe = true;
                               focused = true;
                               break;
                           case "checkBox":
                               control.FocusOnMe = true;
                               focused = true;
                               break;
                           case "dateTime":
                               control.FocusOnMe = true;
                               focused = true;                        
                               break;
                           case "timePicker":
                               control.FocusOnMe = true;
                               focused = true;
                               break;
                           case "fileGallery":                             
                               control.FocusOnMe = true;
                               focused = true;
                               break;
                           case "cameraInput":                             
                               c.FocusOnMe = true;
                               focused = true;
                               break;                        
                           case "listSelection":                             
                               control.FocusOnMe = true;
                               focused = true;
                               break;                       
                           case "numeric":
                               control.FocusOnMe = true;
                               focused = true;
                               break;
                           case "text":
                               control.FocusOnMe = true;
                               focused = true;
                               break;
                           case "menuSelection":
                               control.FocusOnMe = true;
                               focused = true;
                               break;
                           case "listPresentation":
                               control.FocusOnMe = true;
                               focused = true;
                               break;
                       }
                   }
              
               });
           });
           return rows;
       };

       service.validateControls = function (rows) {

           var result = true;

           angular.forEach(rows, function (row) {
               angular.forEach(row.Controls, function (control) {
                   switch (control.Type) {
                       case "binaryOption":
                           if (!(control.IsOptionOne || control.IsOptionTwo) || (control.IsOptionOne && control.RefuseOptionOne))
                               result = false;
                           break;
                       case "checkBox":
                           if (control.MustBeChecked && control.Value === false)
                               result = false;
                           break;
                       case "listSelection":
                           if (control.SelectedIndices.length <= 0 && !control.MultiSelect)
                               result = false;
                           break;
                       case "imageSelection":
                           if (control.SelectedIndices.length <= 0 && !control.MultiSelect)
                               result = false;
                           break;
                       case "numeric":
                           if (!(control.AllowEmpty || control.Value || control.Value === 0))
                               result = false;
                           break;
                       case "text":
                           if (!(control.AllowEmpty || control.Value))
                               result = false;
                           break;
                       case "timePicker":
                           if ((!control.AllowEmpty && control.Value.length === 0) || (control.AllowEmpty && control.validationClass === "invalid"))
                               result = false;
                           break;
                       case "dateTime":
                           if ((!control.AllowEmpty && control.Value.length === 0) || (control.AllowEmpty && control.validationClass === "invalid"))
                               result = false;
                           break;
                       case "menuSelection":
                           if (control.SelectedIndex === 0 && control.AllowEmptyOption === false && control.HasEmptyOption)
                               result = false;
                           break;
                       case "cameraInput":
                           if (control.FileReferences.length <= 0)
                               result = false;
                           break;
                       case "fileGallery":
                           if (control.AllowAdd && !control.AllowAddMany && control.FileReferences.length <= 0)
                               result = false;
                           break;
                   }
               });
           });

           return result;
       }

       service.getLimitedUserStepData = function (rows) {

           var toReturn = [];
           angular.forEach(rows, function (row)
           {
               angular.forEach(row.Controls, function (control) {

                   var c = {};
                   c.__type = control.__type;

                   switch (control.Type) {
                       case "binaryOption":
                           c.IsOptionOne = control.IsOptionOne;
                           c.IsOptionTwo = control.IsOptionTwo;
                           break;
                       case "checkBox":
                           c.Value = control.Value;
                           break;
                       case "dateTime":
                           c.Value = control.Value;
                           break;
                       case "timePicker":
                           c.Value = control.Value;
                           break;
                       case "fileGallery":
                           c.FileReferences = control.FileReferences;
                           c.UserDefinedSize = control.UserDefinedSize;
                           c.SelectedImageSizeMode = control.SelectedImageSizeMode;
                           c.AskImageSize = control.AskImageSize;
                           //do nothing
                           break;
                       case "cameraInput":
                           c.FileReferences = control.FileReferences;
                           //do nothing
                           break;
                       case "header":
                           //do nothing
                           break;
                       case "link":
                           //do nothing
                           break;
                       case "listSelection":
                           c.SelectedIndices = control.SelectedIndices;
                           break;
                       case "imageSelection":
                           c.SelectedIndices = control.SelectedIndices;
                           break;
                       case "numeric":
                           c.Value = control.Value;

                           // Allow both . and , (server excepts invarient)
                           if (control.Value !== undefined && control.Value !== null && control.Value !== '')
                               c.Value = parseFloat(control.Value.toString().replace(',', '.'));
                           
                           break;
                       case "staticText":
                           //do nothing
                           break;
                       case "labeledStaticText":
                           //do nothing
                           break;
                       case "subTaskList":
                           //do nothing
                           break;
                       case "text":
                           c.Value = control.Value;
                           break;
                       case "grid":
                           c.SelectedRowGuids = control.SelectedRowGuids;
                           break;
                       case "calendar":
                           c.SelectedRowGuids = control.SelectedRowGuids;
                           break;
                       case "menuSelection":
                           c.SelectedIndex = control.SelectedIndex;
                           //do nothing
                           break;
                   }

                   toReturn.push(c);

               });
           });        

           return toReturn;
       }

       return service;
   }
]);