app.factory('ListSelectionRowService', [function () {

    var ROW_LIMIT = 200;

    function ListSelectionRowServiceInstance(groups) {
        this.totalRowCount = 0;
        this.rowCollection = {};

        // This is the only time we should loop over all items
        for (var groupIndex = 0; groupIndex < groups.length; groupIndex++) {
            var group = groups[groupIndex];
            for (var rowIndex = 0; rowIndex < group.Rows.length; rowIndex++) {
                this.totalRowCount++;
                var row = group.Rows[rowIndex];
                this.rowCollection[row.RowIndex] = row;
            }
        }

        this.currentGroups = groups;
        this.currentRowCollection = angular.copy(this.rowCollection);
        this.currentRowCount = this.totalRowCount;
        this.showSubsetOfRows = this.totalRowCount > ROW_LIMIT;

        // -----------------
        // Public functions
        // -----------------
        this.rowByRowIndex = function (rowIndex) {
            return this.currentRowCollection[rowIndex];
        };

        this.getNextRowIndex = function (currentRowIndex) {
            if (currentRowIndex === null && this.currentRowCount > 0)
                return 0;
            if (currentRowIndex + 1 < this.currentRowCount)
                return currentRowIndex + 1;

            return currentRowIndex;
        };

        this.getPreviousRowIndex = function (currentRowIndex) {
            if (currentRowIndex === null && this.currentRowCount > 0)
                return 0;
            if (currentRowIndex - 1 > -1)
                return currentRowIndex - 1;

            return currentRowIndex;
        };

        this.clearFilter = function () {
            this.currentGroups = groups;
        };

        this.filter = function (matchRowFunc, matchGroupFunc) {
            this.currentGroups = [];
            for (var groupIndex = 0; groupIndex < groups.length; groupIndex++) {
                var group = angular.copy(groups[groupIndex]);

                // Check match for group
                if (matchGroupFunc(group)) {
                    this.currentGroups.push(group);
                    continue;
                }

                var groupHits = [];
                for (var rowIndex = 0; rowIndex < group.Rows.length; rowIndex++) {
                    var row = group.Rows[rowIndex];

                    // Check match for row
                    if (matchRowFunc(row))
                        groupHits.push(row);
                }

                // Add group if any hits
                if (groupHits.length > 0) {
                    group.Rows = groupHits;
                    this.currentGroups.push(group);
                }
            }
        };

        this.disableSubsetLimit = function () {
            this.showSubsetOfRows = false;
        };

        this.getLimitedGroups = function () {
            if (!this.showSubsetOfRows)
                return this.currentGroups;

            var taken = 0;
            var groupResult = [];
            for (var groupIndex = 0; groupIndex < this.currentGroups.length; groupIndex++) {
                if (taken > ROW_LIMIT)
                    break;

                groupResult.push(this.currentGroups[groupIndex]);
                taken += this.currentGroups[groupIndex].Rows.length;
            }

            return groupResult;
        };

        this.getLimitedRows = function (group) {
            if (!this.showSubsetOfRows)
                return group.Rows;

            if (group.Rows.length > ROW_LIMIT)
                return group.Rows.slice(0, ROW_LIMIT);

            return group.Rows;
        };
    }

    return {
        init: function (groups) { return new ListSelectionRowServiceInstance(groups); }
    };
}]);

