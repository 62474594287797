app.factory('flowHomeService', [
    '$http', function ($http) {

        var service = {};

        service.sendWebClientInfoToFlowHome = function () {
            var toSend = {};
            toSend.WebClientEndpoint = window.location.href;
            return $http.post($.path + "/api/webClientToFlowHome", toSend);
        };

        service.sendPortalInfoToFlowHome = function () {
            var toSend = {};
            toSend.PortalEndpoint = window.location.href;
            return $http.post($.path + "/api/portalToFlowHome", toSend);
        };

        return service;
    }
]);