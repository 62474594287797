app.factory('progressService', ['ngProgress', '$rootScope', function (ngProgress, $rootScope) {

    var service = {};
    var noOfStarted = 0;

    service.open = function () { 
            ngProgress.start();
            noOfStarted++;  
            $rootScope.showLoadingBackground = true;        
    }

    service.close = function () {
            noOfStarted--;

            if (noOfStarted <= 0)
            {
                ngProgress.complete();     
                $rootScope.showLoadingBackground = false;
            }        
    }  

    return service;
}]);