app.factory('historyService', [
    '$rootScope', function ($rootScope) {

        var service = {};

        var historyExpanded = false;

        service.findIndex = function (array, checkFunc) {
            var index = -1;
            for (var i = 0; i < array.length; ++i) {
                if (checkFunc(array[i])) {
                    index = i;
                    break;
                }
            }

            return index;
        }


        service.hideHistoryListAndBroadcast = function () {
            if (historyExpanded) {
                $rootScope.$broadcast('hideHistoryList');
                historyExpanded = false;
            }
        };

        service.hideHistoryList = function () {
            historyExpanded = false;
        };

        service.showHistoryList = function () {
            historyExpanded = true;
        };

        return service;
    }
]);