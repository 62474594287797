app.factory('workflowThemeService', [
    function () {

        var service = {};
        var backgroundColor = '#F2F2F2';

        service.setColor = function (color) {
            if (color !== 'undefined' && color !== "")
                backgroundColor = color;
        };

        service.getColor = function () {
            return backgroundColor;
        };     

        service.LightenDarkenColor = function (col, amt) {

            if (!col) {
                return "#F2F2F2";
            }

            var usePound = false;

            if (col[0] === "#") {
                col = col.slice(1);
                usePound = true;
            }

            var num = parseInt(col, 16);

            var r = (num >> 16) + amt;

            if (r > 255) r = 255;
            else if (r < 0) r = 0;

            var b = ((num >> 8) & 0x00FF) + amt;

            if (b > 255) b = 255;
            else if (b < 0) b = 0;

            var g = (num & 0x0000FF) + amt;

            if (g > 255) g = 255;
            else if (g < 0) g = 0;

            return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
        };

        return service;
    }
]);