app.factory('authenticationService', [
    '$http', '$location', 'sharedSessionService', "logoService", "ultimateOpenIdService", "translationFlowClientService", function ($http, $location, sharedSessionService, logoService, ultimateOpenIdService, translationFlowClientService) {

        var service = {};

        service.login = function (username, password, bearerToken, credentials, openIdInfos) {
            ultimateOpenIdService.resetAuthorizationData(); // just to make sure no one else gets another users openid session
            translationFlowClientService.cleanAll();
            var toSend = {};
            toSend.UserName = username;
            toSend.Password = password;
            toSend.Language = $.language;
            toSend.BearerToken = bearerToken; //Should be sent in a http header with the Authorization : Bearer token format but w/e
            toSend.ExternalCredentials = credentials;
			toSend.AuthenticationInfos = openIdInfos;
            return $http.post($.path + "/api/login", toSend);
        };

        service.loginUsingOpenId = function (scope) {
            ultimateOpenIdService.authorize(scope);
        };

        service.joinTransactionSession = function (username) {
            var toSend = {};
            toSend.UserName = username;
            return $http.post($.path + "/api/joinSession", toSend);
        };

        service.ssoLogin = function () {
            ultimateOpenIdService.resetAuthorizationData(); // just to make sure no one else gets another users openid session
            return $http.get($.flowWebAuthenticator);
        };

        service.validateSitting = function () {
            return $http.get($.path + "/api/validateSitting");
        };

        service.restoreSession = function () {
            return $http.get($.path + "/api/restoreSession");
        };

        service.logout = function () {
            if (ultimateOpenIdService.isOpenIdLoginSession())
                ultimateOpenIdService.logoff(logoutSession());
            else
                logoutSession();
        };

        function logoutSession() {
            sharedSessionService.reset();
            $location.search('tabId', null);
            $location.path("/login");
            logoService.ClearCache();
        };

        //if session is public, logout user when he enters thank you page
        service.logoutIfPublic = function () {            
            if (sharedSessionService.isPublicSession())
            {
                sharedSessionService.reset();
            } 
        };

        service.takeBackSitting = function () {
            return $http.get($.path + "/api/takeBackSitting");
        };

        service.getCredentials = function (username) {
            var toSend = {};
            toSend.UserName = username;

            return $http.post($.path + "/api/credentials", toSend);
        };

        return service;
    }
]);