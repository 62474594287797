app.factory('imageService', ['sharedSessionService','$http',
    function (sharedSessionService, $http) {

           var service = {};

           service.getImage = function (iconIdentifier) {
               if (iconIdentifier) {
                   return $.path + "/api/icon/" + iconIdentifier + "/80";
               }
               else {
                   return $.path + "/api/icon/default/60";
               }
                
           }

           service.getImageInSize = function (iconIdentifier,size) {    
               if (iconIdentifier) {
                   return $.path + "/api/icon/" + iconIdentifier + "/" + size;
               }
               else {
                   return $.path + "/api/icon/default/" + size;
               }
           }

           return service;
       }
    ]);