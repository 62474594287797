app.factory('ultimateOpenIdService', ['$rootScope', 'sharedSessionService', '$window', "$location", "$cookies", '$http', '$uibModal', 'localizationService',
    function ($rootScope, sharedSessionService, $window, $location, $cookies, $http, $uibModal, localizationService) {
        var service = {};

        Oidc.Log.logger = console;
        Oidc.Log.Level = Oidc.Log.INFO;

        var authoriztionCode = "0";
        var implicitFlow = "1";

        var userNameClaimsKey = $.UserNameClaimsKey;

        var configuration = {};

        if ($.authenticationFlow === authoriztionCode) {
            configuration.responseType = "code";
            configuration.silentRenew = true;
        }
        else if ($.authenticationFlow === implicitFlow) {
            configuration.responseType = "id_token token";
            configuration.silentRenew = false;
        }
        
        var settings = {
            authority: $.authorityUrl,
            client_id: $.clientId,
            redirect_uri: window.location.origin + window.location.pathname + "?open_id_login=true",
            post_logout_redirect_uri: window.location.origin + window.location.pathname + "?open_id_logout=true",
            response_type: configuration.responseType,
            scope: $.scope,

            popup_redirect_uri: window.location.origin + window.location.pathname + "?open_id_login=true",
            popup_post_logout_redirect_uri: window.location.origin + window.location.pathname + "?open_id_logout=true",
            silent_redirect_uri: window.location.origin + window.location.pathname + "?open_id_refresh=true",

            filterProtocolClaims: true,
            loadUserInfo: false, // We do not care about additional identity data (resolves CORS issue)

            automaticSilentRenew: configuration.silentRenew,
            clockSkew: $.clockSkew,
            revokeAccessTokenOnSignout: true,

            extraQueryParams: $.extraQueryParams
        };
        
        service.mgr = new Oidc.UserManager(settings);

        service.mgr.events.addSilentRenewError(function (e) {
            service.mgr.signoutRedirect();
        });

        service.mgr.events.addUserLoaded(function (e) {
            setAuthorizationData(e);
        });

        service.setOpenIdInfo = function (config) {
            service.resetAuthorizationData();
            var date = new Date();
            date.setTime(date.getTime() + (sharedSessionService.days * 24 * 60 * 60 * 1000));
            $cookies.putObject('openIdInfo', config, {
                expires: date
            });
        };

        service.getOpenIdInfo = function () {
            return $cookies.getObject("openIdInfo");
        };

        service.isOpenIdLoginSession = function () {
            var cookie = service.getOpenIdInfo();
            return cookie && cookie.AccessTokenExpiration;
        };

        service.checkOpenIdLoginValid = new Promise(function (valid, invalid) {
            // executor (the producing code, "singer")
            if (service.isOpenIdLoginSession()) {

                var openIdInfo = service.getOpenIdInfo();

                if (openIdInfo.AccessTokenExpiration.Expired)
                    invalid("logged out");
                else
                    valid(true);
            } else {
                valid(true);
            }
        });

        service.logoff = function (action) {
            service.mgr.signoutPopup().then(function () {
                //console.log("OpenId successfully logged out");
            }).catch(function () {
                //console.log("Signout errort");
            }).finally(function () {
                service.resetAuthorizationData();
                action;
            });
        };

        service.signOutOpenId = function () {
            service.resetAuthorizationData();
            service.mgr.signoutRedirect();
        };

        service.resetAuthorizationData = function () {
            $cookies.remove("openIdInfo");
        };

        function setAuthorizationData(user) {
            if (user) {
                var openIdAuthData = {};
                var expDate = new Date();
                expDate.setSeconds(expDate.getSeconds() + parseInt(user.expires_in));

                openIdAuthData.AccessToken = user.access_token;
                openIdAuthData.IdentityToken = user.id_token;
                openIdAuthData.RefreshToken = user.refresh_token;
                openIdAuthData.AccessTokenExpiration = expDate;
                openIdAuthData.UserName = getUserName(user);

                service.setOpenIdInfo(openIdAuthData);
            }
        };

        function getUserName(user) {
            return user.profile[userNameClaimsKey];
        };

        function logoutSession() {
            sharedSessionService.reset();
            $location.search('tabId', null);
            $location.path("/login");
        };

        // FOR NEW OPENID POPUP

        function containsParam(param) { // contains param and returns it value
            var url = window.location.href;
            var name = param.replace(/[\[\]]/g, '\\$&');
            var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        };

        service.isHandleOpenIdCallback = function () {
            return containsParam('open_id_login') || containsParam('open_id_logout') || containsParam('open_id_refresh');
        };

        service.handleOpenIdCallback = function () {
            if (containsParam('open_id_login')) {// contains and value is true
                service.mgr.signinPopupCallback();
            }
            else if (containsParam('open_id_logout')) // contains and value is true
            {
                logoutSession();
            }
            else if (containsParam('open_id_refresh')) // refresh succeeded
            {
                setAuthorizationData(service.mgr.user);
            }
        };

        service.authorize = function (scope) {
            service.mgr.signinPopup().then(function (user) {
                setAuthorizationData(user);
                service.handleOpenLogin(user, scope);
            }).catch(function (err) {
                //console.log(err);
            });
        };

        service.getOpenIdExternalCredentials = function (openIdUserInfo) {
            return $http.post($.path + "/api/credentials", { Username: getUserName(openIdUserInfo) }); 
        };

        service.handleOpenLogin = function (openIdUserInfo, scope) {
            service.getOpenIdExternalCredentials(openIdUserInfo).then(function (result) {
                scope.model.credentials = result.data.ExternalCredentials;
                scope.model.openIdUser = openIdUserInfo;
                scope.model.passwordRequired = false;
                scope.model.openIdLogin = true;

                if (scope.model.credentials.length === 0) {
                    service.sendLoginRequest(scope);
                } else {
                    var modalInstance = $uibModal.open({
                        templateUrl: $.sharedAppDir + "/views/credentials.html",
                        controller: 'credentialsController',
                        backdrop: 'static',
                        keyboard: true,
                        resolve: {
                            data: function () {
                                return scope.model;
                            }
                        }
                    });

                    modalInstance.result.then(function () {
                        service.signOutOpenId();
                    }, function () {
                        // Success login
                    }).catch(angular.noop);
                }
            }).catch(function (ex) {
                //console.error(ex);
                service.signOutOpenId();
            });
        };

        service.sendLoginRequest = function (scope) {
            var loginRequest = {};
            loginRequest.IdToken = scope.model.openIdUser.id_token;
            loginRequest.AccessToken = scope.model.openIdUser.access_token;
            loginRequest.RefreshToken = scope.model.openIdUser.refresh_token;
            loginRequest.Language = $.language;
            loginRequest.ExternalCredentials = scope.model.credentials;
            $http.post($.path + "/api/loginWithIdToken", loginRequest).then(function (result) {
                result.data.IsOpenId = true; // so we can validate openid cookie & regular cookie
                sharedSessionService.set(angular.fromJson(result.data));
                localizationService.setLocalization(result.data.LanguageCode, result.data.Culture);
                if ($rootScope.urlPath)
                    $location.path($rootScope.urlPath);
                else
                    $location.path("/");
            }).catch(function (ex) {
                console.log(ex); // should not happen..
            });
        };

        return service;
    }
]);