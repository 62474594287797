app.service("fingerPrintService", ['appConfig', function (appConfig) {

    var fingerPrint = undefined; 

    var prepareFingerPrint = function prepareFingerPrint()
    {
        fingerPrint = new Fingerprint().get();
    }

    var getFingerPrint = function getFingerPrint() {

        if (fingerPrint == undefined)
        {
            prepareFingerPrint(); 
        }

        return fingerPrint + "_" + appConfig.APPLICATION_TYPE;
    }

    return {
        prepare: prepareFingerPrint,
        get: getFingerPrint 
    }
}])